module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"guardian-of-life","short_name":"GL","start_url":"/","background_color":"#8BBDBF","theme_color":"#8BBDBF","display":"minimal-ui","icon":"src/favicon/favicon-black.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"fa75a1dec6bdd1b2cc94c87889e5a14a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":140,"withWebp":{"quality":90},"quality":90,"wrapperStyle":"margin: 0;"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
